import { z } from 'zod';

import { type MotivationPattern } from './motivations';

type ModelBase = {
  id: number;
  name?: string;
  uniqueName?: string;
  tags: string[];
  createdDate: string;
  updatedDate: string;
  modelFeedback: ModelFeedback[];
  modelMotivations: ModelMotivation[];
  scope: 'global' | 'workspace' | 'user';
  isActive?: boolean;
  hasBeenLimited?: boolean;
  match?: ModelMatch;
};

export type ModelMatch = {
  id: number;
  score: number;
  motivations: {
    id: number;
    code: string;
    score: number;
  }[];
};

export type CultureModel = ModelBase & {
  type: 'standard';
  isActive: boolean;
};

export type InsightModel = ModelBase & {
  type: 'insight' | 'xfactor' | 'role';
  uniqueName: string;
  hasBeenLimited: boolean;
  match?: ModelMatch;
};

export type Model = CultureModel | InsightModel;

export type InsightResult = InsightModel & {
  strengths: MotivationPattern[];
  blindspots: MotivationPattern[];
  matchScore: number;
  areAllMotivationsFilled?: boolean;
  lastViewedDate?: string;
};

export const ModelMotivationCreateSchema = z.object({
  motivationId: z.number(),
  greenHigh: z.number(),
  greenLow: z.number(),
  orangeHigh: z.number(),
  orangeLow: z.number(),
  weight: z.number().optional(),
});
export type ModelMotivationCreate = z.infer<typeof ModelMotivationCreateSchema>;

export type ModelMotivation = ModelMotivationCreate & {
  id?: number;
  motivation: MotivationDefinition;
  distribution?: number[];
  size?: number;
  weight?: number;
};

export type ModelFeedback = {
  id: number;
  modelId: number;
  userId: number;
  createdDate: string;
  updatedDate: string;
  type: 'boolean' | 'range';
  isPositive: boolean | null;
  rating: number | null;
  comment: string | null;
};

type ModelScope = 'global' | 'workspace' | 'user';

export type ModelDetailWithFeedback = {
  id: number;
  name: string | null;
  uniqueName: string | null;
  scope: ModelScope;
  tags: string[];
  isVisible: boolean;
  createdDate: string;
  updatedDate: string;
  cultureCode: string | null;
  thumbsUpCount: number;
  thumbsDownCount: number;
  modelFeedback: ModelFeedback[];
};

export type MotivationDefinition = {
  id: number;
  code: string;
  name: string;
  description: string;
  intervals: number;
  motivationGroupId: number;
  motivationGroup: { id: number; name: string; slug: string };
};

export type ModelRankingResult = {
  rank: number;
  score: number;
  userId: number;
  firstName?: string;
  lastName?: string;
  avatarUrl?: string;
  countryCode?: string;
  currentUser?: boolean;
};

export type ModelRankingRequest = {
  modelId?: string;
  orderedQuery?: string;
  organizationId?: number;
  groupId?: number;
  userId?: number | number[];
  id?: number | number[];
  page?: number;
  pageSize?: number;
  scope?: number;
};

export type ModelMatchRequest = {
  modelId?: number;
  modelName?: string;
  poolIds?: number[];
  workspaceIds?: number[];
  userIds?: number[];
  countryCodes?: string[];
  occupations?: string[];
  searchText?: string;
  orderBy?: 'ASC' | 'DESC';
  page?: number;
  pageSize?: number;
};

export type ModelMatchResponse = {
  results: ModelRankingResult[];
  isLimited: boolean;
  isTotalLimited: boolean;
  totalRankings: number;
  limitQuantity: number;
};

export type ModelUserRankResponse = {
  rank: number;
  total: number;
  top: ModelRankingResult[];
  contextual: ModelRankingResult[];
};

export type ModelViewedEventTrackingProp = {
  cardName: string;
  match: number | undefined;
  order: number;
  motivation?: string;
  motivationScore?: string;
  motivationMatch?: string;
  motivationResult?: string;
  numberOfTeams?: number;
  groupSize?: number;
};

export const WorkspaceModelCreateSchema = z.object({
  workspaceId: z.number(),
  name: z.string(),
  modelMotivations: z.array(ModelMotivationCreateSchema),
});
export type WorkspaceModelCreate = z.infer<typeof WorkspaceModelCreateSchema>;

export const WorkspaceModelUpdateSchema = z.object({
  workspaceId: z.number(),
  modelId: z.number(),
  name: z.string().optional(),
  modelMotivations: z.array(ModelMotivationCreateSchema).optional(),
});
export type WorkspaceModelUpdate = z.infer<typeof WorkspaceModelUpdateSchema>;

export const WorkspaceModelDeleteSchema = z.object({
  workspaceId: z.number(),
  modelId: z.number(),
});
export type WorkspaceModelDelete = z.infer<typeof WorkspaceModelDeleteSchema>;
