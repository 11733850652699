import { z } from 'zod';

import { WorkspaceModelUpdateSchema } from './models';
import { TeamUpdateSchema } from './teams';
import { UserSchema } from './user';

export const SwitchShareCodeSchema = z.object({
  shareCode: z.string(),
  enabled: z.boolean(),
});

export const ShareTypeSchema = z.enum(['dashboard', 'report']);
export type ShareType = z.infer<typeof ShareTypeSchema>;

export const ShareScopeSchema = z.enum(['published', 'anyoneWithLink', 'private']);
export type ShareScope = z.infer<typeof ShareScopeSchema>;

const BaseSchema = z.object({
  id: z.number(),
  code: z.string(),
  ownerId: z.number(),
  scope: ShareScopeSchema,
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
});

export const ReportReferenceSchema = z.object({
  type: z.literal('report'),
  reportId: z.number(),
});
export const DashboardReferenceSchema = z.object({
  type: z.literal('dashboard'),
  dashboardId: z.number(),
});
export const DashboardInstanceReferenceSchema = z.object({
  type: z.literal('dashboardInstance'),
  dashboardInstanceId: z.number(),
});

export const ReportShareSchema = BaseSchema.merge(ReportReferenceSchema);
export type ReportShare = z.infer<typeof ReportShareSchema>;

export const DashboardShareSchema = BaseSchema.merge(DashboardReferenceSchema);
export type DashboardShare = z.infer<typeof DashboardShareSchema>;

export const DashboardInstanceShareSchema = BaseSchema.merge(
  DashboardInstanceReferenceSchema,
);
export type DashboardInstanceShare = z.infer<typeof DashboardInstanceShareSchema>;

export const ResourceReferenceSchema = z.union([
  ReportReferenceSchema,
  DashboardReferenceSchema,
  DashboardInstanceReferenceSchema,
]);
export type ResourceReference = z.infer<typeof ResourceReferenceSchema>;

export const ShareViewerRequestSchema = z.union([
  z.object({
    type: z.literal('space'),
    spaceId: z.number(),
  }),
  z.object({
    type: z.literal('user'),
    userId: z.number(),
  }),
  z.object({
    type: z.literal('team'),
    teamId: z.number(),
  }),
]);
export type ShareViewerRequest = z.infer<typeof ShareViewerRequestSchema>;

export const ShareViewerSchema = z.object({ id: z.number() }).and(
  z.union([
    z.object({
      space: WorkspaceModelUpdateSchema,
    }),
    z.object({
      user: UserSchema,
    }),
    z.object({ team: TeamUpdateSchema }),
  ]),
);
export type ShareViewer = z.infer<typeof ShareViewerSchema>;

export const ShareSchema = z
  .object({ viewers: ShareViewerSchema.array() })
  .and(z.union([ReportShareSchema, DashboardShareSchema, DashboardInstanceShareSchema]));
export type Share = z.infer<typeof ShareSchema>;

const createOmits = {
  id: true,
  ownerId: true,
  code: true,
  createdAt: true,
  updatedAt: true,
} as const;
export const ShareReportCreateRequestSchema = ReportShareSchema.omit(createOmits);
export const ShareDashboardCreateRequestSchema = DashboardShareSchema.omit(createOmits);
export const ShareDashboardInstanceCreateRequestSchema =
  DashboardInstanceShareSchema.omit(createOmits);
export const ShareCreateRequestSchema = z
  .object({ viewers: ShareViewerRequestSchema.array().optional() })
  .and(
    z.union([
      ShareReportCreateRequestSchema,
      ShareDashboardCreateRequestSchema,
      ShareDashboardInstanceCreateRequestSchema,
    ]),
  );
export type ShareCreateRequest = z.infer<typeof ShareCreateRequestSchema>;
export type ShareReportCreateRequest = z.infer<typeof ShareReportCreateRequestSchema>;
export type ShareDashboardCreateRequest = z.infer<
  typeof ShareDashboardCreateRequestSchema
>;
